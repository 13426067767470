import React, { Component } from 'react';
import axios from 'axios';
import './App.css';
import Masonry from 'react-masonry-component';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

const flattenMedia = (data, showSensitive) => {
  const localMax = Math.max.apply(null, data.map(a => a.favourites_count + a.reblogs_count));
  if (!showSensitive) {
    data = data.filter(a => !a.sensitive);
  }
  return data.reduce((arr, cur) => arr.concat(cur.media_attachments.filter(a => a.type === 'image').map(a => Object.assign({}, a, { status: cur, highlighted: cur.media_attachments.length === 1 && (cur.favourites_count + cur.reblogs_count >= localMax) }))), []);
};

const lastId = data => data.length > 0 ? data[data.length - 1].id : false;

const defaultConfig = {
  title: 'Gallery',
  baseURL: '/',
  hashtag: false,
  onlyLocal: true,
  showSensitive: true,
};

class App extends Component {

  constructor (props) {
    super(props);

    this.axios           = null;
    this.handleScroll    = this.handleScroll.bind(this);
    this.loadMore        = this.loadMore.bind(this);
    this.loadInitialPage = this.loadInitialPage.bind(this);
    this.setupAxios      = this.setupAxios.bind(this);
    this.pagePath        = this.pagePath.bind(this);
    this.nextPageParams  = this.nextPageParams.bind(this);
  }

  state = {
    config: defaultConfig,
    initialized: false,
    loading: true,
    items: [],
    nextId: false,
  };

  componentDidMount () {
    axios.get('config.json').then(({ data }) => {
      this.setState({ config: data });
      return data;
    }).catch(() => {}).then(config => {
      this.setupAxios(config);
      this.loadInitialPage();
    });

    window.addEventListener('scroll', this.handleScroll);
  }

  setupAxios (config = defaultConfig) {
    this.axios = axios.create({
      baseURL: config.baseURL,
    });
  }

  handleScroll () {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    const offset = scrollHeight - scrollTop - clientHeight;

    if(offset < 400 && this.state.nextId && !this.state.loading) {
      this.loadMore();
    }
  }

  loadInitialPage () {
    this.axios.get(this.pagePath(), { params: this.nextPageParams() }).then(({ data })=> {
      this.setState({
        items: flattenMedia(data, this.state.config.showSensitive),
        loading: false,
        nextId: lastId(data),
        initialized: true,
      });
    });
  }

  pagePath () {
    const { hashtag } = this.state.config;

    if (hashtag) {
      return `/api/v1/timelines/tag/${hashtag}`;
    } else {
      return '/api/v1/timelines/public';
    }
  }
  nextPageParams () {
    const { config: { onlyLocal }, nextId } = this.state;

    return {
      local: onlyLocal,
      only_media: true,
      max_id: nextId || undefined,
    };
  }

  loadMore () {
    if (!this.state.nextId) {
      return;
    }

    this.setState({ loading: true });

    this.axios.get(this.pagePath(), { params: this.nextPageParams() }).then(({ data }) => {
      this.setState({
        items: this.state.items.concat(flattenMedia(data, this.state.config.showSensitive)),
        loading: false,
        nextId: lastId(data),
      })
    });
  }

  renderItem (item) {
    const previewUrl    = item.preview_url;
    const previewWidth  = item.meta.small.width;
    const originalUrl   = item.url;
    const originalWidth = item.meta.original.width;
    const displayName   = item.status.account.display_name.trim().length > 0 ? item.status.account.display_name : item.status.account.acct.split('@')[0];

    return (
      <div key={originalUrl} className={classNames('item', { 'item--larger': item.highlighted })}>
        <img className='item__image' src={previewUrl} srcSet={`${originalUrl} ${originalWidth}w, ${previewUrl} ${previewWidth}w`} sizes={item.highlighted ? 'calc(0.4 * 100vw)' : 'calc(0.2 * 100vw)'} alt={item.description} />

        <a className='item__source' href={item.status.url} target='_blank' rel='noopener noreferrer'>
          <img src={item.status.account.avatar} alt={item.status.account.acct} width={36} height={36} />

          <div>
            <bdi>{displayName}</bdi>
            @{item.status.account.acct}
          </div>
        </a>
      </div>
    );
  }

  render() {
    const { initialized, items, config } = this.state;

    if (!initialized) {
      return (
        <div className='loading'>
          <p>Loading&hellip;</p>
        </div>
      );
    }

    const options = {
      itemSelector: '.item',
      columnWidth: '.sizer',
      percentPosition: true,
    };

    return (
      <div className='grid'>
        <Helmet>
          <title>{config.title}</title>
        </Helmet>

        <Masonry options={options}>
          <div className='sizer' />

          {items.map(item => this.renderItem(item))}
        </Masonry>
      </div>
    );
  }
}

export default App;
